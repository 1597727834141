.pulse {
	@apply bg-green-800 bg-opacity-10;
	box-shadow: 0 0 0 0 rgba(134 239 172, 0.5);
	transform: scale(1);
	animation: pulse 2s infinite;
}

.pulse_manage {
	@apply bg-green-400 bg-opacity-10;
	box-shadow: 0 0 0 0 rgba(134 239 172, 0.5);
	transform: scale(1);
	animation: pulse 2s infinite;
}

.dark .pulse_manage {
	@apply bg-green-800  bg-opacity-10;
	box-shadow: 0 0 0 0 rgba(134 239 172, 0.5);
	transform: scale(1);
	animation: pulse 2s infinite;
}

/* since the cast button inside the manage element is already animating, we don't need the animation */
.pulse_manage:hover {
	@apply bg-green-400 bg-opacity-10;
	box-shadow: 0 0 0 0 rgba(134 239 172, 0.5);
	transform: scale(1);
	animation: pulse 0s infinite;
}

.dark .pulse_manage:hover {
	@apply bg-green-800 bg-opacity-10;
	box-shadow: 0 0 0 0 rgba(134 239 172, 0.5);
	transform: scale(1);
	animation: pulse 0s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(134, 239, 172, 0.5);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 5px rgba(134, 239, 172, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(134, 239, 172, 0);
	}
}

.container {
	display: grid;
	place-content: center;
	place-items: center;
}

.element {
	grid-row: 1;
	grid-column: 1;
}